<template>
  <div>
    <header-slot v-if="moduleId !== 16" />
    <!--    <header-slot></header-slot>-->
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.meta.pendingRoute }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >PENDING</b-nav-item
      >
      <b-nav-item
        :to="{ name: $route.meta.completedRoute }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >COMPLETED</b-nav-item
      >
      <b-nav-item
        :to="{ name: $route.meta.rejectedRoute }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >REJECTED</b-nav-item
      >
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {
  props: {},
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>
